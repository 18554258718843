.calendar {
    position: absolute;
    top: 250px;
    left: 30px;
    z-index: 1;
    width: 220px;
    height: auto;
}
.react-calendar {
    background-color: #c99c80;
    color: orange;
    border: 2px solid #c99c80;
    border-radius: 8px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}
.react-calendar__navigation button {
    color: orange;
    min-width: 44px;
    background: none;
    font-size: 14px;
    margin-top: 8px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: transparent;
}
.react-calendar__navigation button[disabled] {
    background-color: transparent;
}
abbr[title] {
    text-decoration: none;
}
.react-calendar__month-view__days__day {
    color: #c99c80;
}
.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}
.react-calendar__tile:enabled:hover {
    background: #c99c8053;
    color: #c99c80;
    border-radius: 6px;
}
.react-calendar__tile--now {
    background: #c99c8053;
    border-radius: 6px;
    font-weight: bold;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: transparent;
    border-radius: 6px;
    font-weight: bold;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: transparent;
}
.react-calendar__tile--active {
    background: transparent;
    border-radius: 6px;
    font-weight: bold;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: transparent;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: transparent;
}
.react-calendar__tile--range {
    background: transparent;
    border-radius: 0;
}
.react-calendar__tile--rangeStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: transparent;
}
.react-calendar__tile--rangeEnd {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: transparent;
}
