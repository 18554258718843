* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: url("../pictures/dark_wood.jpg");
    border-radius: 5px;
}

.main {
    display: flex;
    position: relative;
    align-items: center;
}

.tools {
    min-width: 200px;
    display: block;
    position: static;
}
.dashboard {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.task_dashboard {
    display: flex;
    justify-content: space-between;
}

.select {
    color: #c99c80;
    background: #824a25;
    min-width: 100px;
    min-height: 15px;
    padding: 5px 12px;
    margin-left: 5px;
}

.taskForm {
    padding: 15px 15px;
}

.input {
    width: 250px;
    color: #c99c80;
}

h1 {
    text-align: center;
    color: #dd6337;
    font-size: 32px;
    margin-bottom: 20px;
    margin-top: 20px;
}

h2 {
    text-align: center;
    color: orange;
}

.projects {
    margin-left: 80px;
}
.projectLink {
    color: #dd6337;
    font-weight: bold;
}

.project {
    min-width: 650px;
    max-width: 1000px;
    position: relative;
}
.tasks {
    width: 650px;
    text-align: left;
    color: #c99c80;
    background: url("../pictures/brown_wood.jpg");
    margin: 10px 10px;
    padding: 10px 10px;
    border: 2px solid #c99c80;
}

.project,
.task {
    display: flex;
    padding: 10px;
    border: 2px solid #c99c80;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
}

.project_content {
    width: 300px;
    padding-right: 10px;
    color: #dd6337;
    text-transform: uppercase;
}

.task_content {
    color: #e3864a;
}

.tasks * {
    background: url("../pictures/brown_wood.jpg");
}

.task_content_time {
    display: flex;
    margin-left: 18px;
    margin-top: 5px;
    color: #c99c80;
}

.task_content_time_each {
    width: 200px;
}

.notActive {
    text-decoration: line-through;
    text-decoration-thickness: 2px;
}

.project_btn,
.task_btn {
    display: flex;
}

.task-enter-active {
    transform: translateX(0px);
    transition: all 500ms ease-in;
}

.task-exit {
    opacity: 1;
}

.task-exit-active {
    transform: translateX(-350px);
    transition: all 500ms ease-in;
}

.navbar {
    display: flex;
    align-items: center;
    height: 50px;
    width: 100vw;
    padding: 10px 20px;
    background: url("../pictures/brown_wood.jpg");
}

.navbar a:link,
.navbar a:visited {
    padding: 10px;
    color: #c99c80;
    text-decoration: none;
    font: bold 14px Arial;
    background: url("../pictures/brown_wood.jpg");
}

.navbar a:hover,
.navbar a:active {
    color: #dd6337;
    text-decoration: none;
}

.hr-shadow {
    margin: 20px 0;
    padding: 0;
    height: 10px;
    border: none;
    border-top: 3px solid #333;
    box-shadow: 0 10px 10px -10px #8c8b8b inset;
}
